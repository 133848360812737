@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
  text-decoration: none;
  margin: 0;
  padding: 0;
}

.slick-next:before,
.slick-prev:before {
  color: #1d84bd !important;  
}
.slick-next, .slick-prev{
    position: absolute !important;
    top: 50% !important;  
    z-index: 1111;    
}
.sliderCard {
  display: flex !important;
  padding-inline: 40px;
  flex-direction: column !important;
  gap: 3px !important;
  justify-content: center !important;
  align-items: center !important;
}
@media screen and (max-width: 639px) {
  .slick-next{
    right: 9px;
    top: 55px;
  }
  .slick-prev{
    left: 9px;
    top: 55px;
  }
}
@media screen and (min-width: 640px) {
  .slick-next{
    right: 9px;
    top: 25px;
  }
  .slick-prev{
    left: 9px;
    top: 25px;
  }
}
/* 
.slick-track div div{
  display:  flex !important;
}
.slick-next, .slick-prev {
  width: 35px !important;
  height: 35px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50%;
  background-color: #1d84bd !important;
  z-index: 11;
}
.slick-prev {
  left: -50px !important;
}
.slick-next{
  right: -50px !important;
}
@media screen and (min-width: 992px) {
  .active button{
    display: none  !important;
  }
}
@media screen and (max-width: 639px) {
  .slick-next{
    right:-10px !important
  }
  .slick-prev{
    left:-10px !important
  }
  .slick-next, .slick-prev{
    top: 50px;
    width: 30px !important;
    height: 30px !important;
  }
}
@media screen and (min-width: 640px) {
  .slick-next, .slick-prev{

  }
}
@media screen and (min-width: 768px) {

}
@media screen and (min-width: 992px) {

}
@media screen and (min-width:  1024px) {
  
}
@media screen and (min-width:  1280px) {
 
}
@media screen and (min-width:  1536px) {
 
} */
